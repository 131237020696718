<template>
  <div v-loading="loading1" element-loading-text="正在生成excel，请稍后...">
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>退款管理</el-breadcrumb-item>
      <el-breadcrumb-item>欧希思申请退款列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-input v-model="tel" placeholder="请输入用户电话号" style="width: 160px;margin-right:10px;" size="small"></el-input>
      <!-- <p style="float: left;margin: 0;">选择状态：</p> -->
      <el-select v-model="select2" placeholder="请选择状态" style="width:150px;margin-right:10px;" size="small">
        <el-option v-for="item in options2" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <!-- <p style="float: left;margin: 0;margin-left:20px;">选择日期：</p>-->
      <el-date-picker v-model="date" style="width:280px;margin-right: 10px;" type="daterange" :clearable="false" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" size="small">
      </el-date-picker>
      <el-button type="primary" @click="getlist(1)" size="small">查询</el-button>
      <el-button type="primary" @click="exportorder" size="small">导出Excel</el-button>
    </div>
    <el-table :data="list" border style="width: 100%" v-loading="loading">
      <el-table-column prop="orderId" label="订单号"></el-table-column>
      <el-table-column prop="tel" label="客户电话"></el-table-column>
      <el-table-column label="医生姓名" prop="docName"></el-table-column>
      <el-table-column label="订单时间" prop="createTime"></el-table-column>
      <el-table-column v-if="isRefund" label="退款时间" prop="refundTime"></el-table-column>
      <el-table-column label="支付状态" prop="status">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.status == 0">未支付</span>
            <span v-if="scope.row.status == 1">已支付</span>
            <span v-if="scope.row.status == 9">申请退款</span>
            <span v-if="scope.row.status == 10">退款成功</span>
            <span v-if="scope.row.status == 11">驳回申请</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单金额" prop="total"></el-table-column>
      <el-table-column label="" prop="status" width="160">
        <template slot-scope="scope">
          <div class="opt doc">
            <p>
              <el-button size="mini" type="success" v-if="scope.row.status == 9" @click="agree(scope.row)">同意</el-button>
              <el-button size="mini" type="warning" v-if="scope.row.status == 9" @click="notagree(scope.row)">拒绝</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value">
      </el-pagination>
    </p>
    <el-table :data="tableData" border style="width: 100%" id="tableBox" v-show="false">
      <el-table-column prop="orderId" label="订单号"></el-table-column>
      <el-table-column prop="tel" label="客户电话"></el-table-column>
      <el-table-column label="医生姓名" prop="docName"></el-table-column>
      <el-table-column label="订单时间" prop="createTime"></el-table-column>
      <el-table-column v-if="isRefund" label="退款时间" prop="refundTime"></el-table-column>
      <el-table-column label="支付状态" prop="status">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.status == 0">未支付</span>
            <span v-if="scope.row.status == 1">已支付</span>
            <span v-if="scope.row.status == 9">申请退款</span>
            <span v-if="scope.row.status == 10">退款成功</span>
            <span v-if="scope.row.status == 11">驳回申请</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单金额" prop="total"></el-table-column>
    </el-table>
  </div>
</template>
<script>
const FileSaver = require("file-saver");
const XLSX = require("xlsx");
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      date: [],
      select2: "",
      options2: [
        { id: 9, value: "申请退款" },
        { id: 10, value: "退款成功" },
        { id: 11, value: "驳回申请" },
      ],
      sid: "",
      loading: false,
      tel: "",
      date: "",
      isRefund: false,
      tableData: [],
      loading1: false,
    };
  },
  mounted() {
    // this.getlist(1);
    this.sid = sessionStorage.getItem("id");
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getlist(currentPage);
    },
    getlist(page) {
      var telstr = "",
        datestr = "";
      if (this.select2 == 10) {
        this.isRefund = true;
      } else {
        this.isRefund = false;
      }
      if (this.select2 == "") {
        this.$message({
          message: "请必须选择状态",
          type: "error",
        });
        return;
      }
      if (this.tel != "") {
        telstr = "&tel=" + this.tel;
      }
      if (this.date.length > 0) {
        datestr = "&start=" + this.date[0] + "&end=" + this.date[1];
      }
      this.axios
        .get(
          "https://live-server.51xk.info/api/SignUpOrderCount/get_list?index=" +
            page +
            "&pagesize=" +
            this.PageSize +
            "&status=" +
            this.select2 +
            telstr +
            datestr
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.list = res.data.result.list;
            this.totalCount = res.data.result.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    agree(row) {
      this.$confirm("此操作将退款给患者, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true; //http://121.89.211.59:20210
          this.axios
            .post(
              "https://live-server.51xk.info/api/SignUpOrderCount/refund",
              this.qs.stringify({ orderId: row.orderId })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.loading = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    notagree(row) {
      this.$confirm("确定拒绝此申请吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true; //http://121.89.211.59:20210 http://live-server.51xk.info
          this.axios
            .post(
              "https://live-server.51xk.info/api/SignUpOrderCount/reject_refund",
              this.qs.stringify({ orderId: row.orderId })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.loading = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行下载
    async exportorder() {
      await this.setData();
      this.exportOut();
    },
    setData: async function () {
      this.loading1 = true;
      this.arr = [];
      this.tableData = [];
      let num = Math.ceil(this.totalCount / this.PageSize);
      let m = "";
      let a = [];
      for (m = 1; m <= num; m++) {
        a = await this.funA(m);
        this.arr = this.arr.concat(a.data.result.list);
      }
      this.tableData = this.arr;
    },
    funA: async function (m) {
      var telstr = "",
        datestr = "";
      if (this.tel != "") {
        telstr = "&tel=" + this.tel;
      }
      if (this.date.length > 0) {
        datestr = "&start=" + this.date[0] + "&end=" + this.date[1];
      }
      return await this.axios.get(
        // "https://live-server.51xk.info/api/SignUpOrderCount/get_list?index=" +
        //   m +
        //   "&pagesize=" +
        //   this.PageSize +
        //   str

        "https://live-server.51xk.info/api/SignUpOrderCount/get_list?index=" +
          m +
          "&pagesize=" +
          this.PageSize +
          "&status=" +
          this.select2 +
          telstr +
          datestr
      );
    },
    //下载表格
    exportOut() {
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#tableBox"),
        xlsxParam
      );
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          new Date().getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      this.loading1 = false;
      return wbout;
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.opt p {
  margin: 0 0 8px 0;
}
</style>
